@import "../../themes.scss";

.top-bar {
  background-color: #fff;
  padding: 0px 20px;

  .icon-right {
    width: 26px;
    height: 26px;
    color: gray;
  }

  .user-name {
    font-size: $font-size-subtitle;
    color: gray;
  }

  .link-icon {
    font-size: 26px;
    color: $primary-color;
  }

  .btn-menu {
    // border: 1px solid $primary-color;
    // border-radius: 20px;
    padding: 0px 10px 0px 0px;
    height: 35px;

    .avatar-user {
      width: 35px;
      height: 35px;
      // border: 2px solid $primary-color;
    }

    .icon-menu {
      color: $primary-color;
    }

    .username-text {
      color: $primary-color;
      font-family: $font-kantumruy-pro;
      font-size: $font-size-body;
      font-weight: bold;
    }
  }
}
@import "../themes.scss";

.setting-page {
  .page-header {
    height: 40px;

    .slash {
      height: 40px;
      width: 4px;
      background-color: $primary-color;
    }

    .page-title {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }

    .breadcrumbs-title {
      font-family: $font-kantumruy-pro;
      color: black;
      font-size: $font-size-body;
    }

    .page-link-title {
      font-family: $font-kantumruy-pro;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .search-title {
    color: #0f81c2;
    font-family: $font-kantumruy-pro;
  }

  .header-text {
    font-size: 14px;
    font-family: $font-kantumruy-pro;
    color: $primary-color;
    padding: 0px 0px 5px 5px;
    // font-weight: 500;
    margin-top: -5px;
  }

  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-kantumruy-pro;

    .css-nu79wc-MuiInputAdornment-root {
      color: $primary-color !important;
    }

    .MuiOutlinedInput-input {
      font-weight: bold;
      color: $primary-color;
      font-family: $font-kantumruy-pro;
      // border-radius: 6px;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-kantumruy-pro;
      font-weight: bold;
      color: $primary-color;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-kantumruy-pro;
      border-radius: 6px;
      font-weight: bold;
      color: $primary-color;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      font-weight: bold !important;
      color: $primary-color !important;
      font-family: $font-kantumruy-pro !important;
      border-radius: 6px !important;
    }

    .MuiInputLabel-root.Mui-focused {
      font-weight: bold;
      color: $primary-color;
      font-family: $font-kantumruy-pro;
      border-radius: 6px;
    }

    .supplier-detail-box {
      justify-content: space-between;
    }
  }

  .btn-create {
    font-family: $font-kantumruy-pro;
    font-size: $font-kantumruy-pro;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    height: 100%;

    .icon-add {
      width: 18px;
      height: 18px;
    }

    .icon-save {
      margin-right: 15px;
      width: 18px;
      height: 18px;
    }
  }

  .setting-page-container {
    background-color: #fff;
    padding: 15px 20px;
    border-radius: 10px;
    height: 100px;

    .image {
      width: 40px;
      height: 40px;
      color: $primary-color;
    }

    .page-title {
      margin: 5px 0px;
      font-family: $font-kantumruy-pro;
      font-size: $font-size-subtitle;
      color: $primary-color;
      font-weight: bold;
    }

    .page-description {
      width: 100%;
      font-family: $font-kantumruy-pro;
      font-size: 14px;
      color: $primary-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
      color: #191919;
    }
  }

  .transportation-card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgb(226, 224, 224);
    padding: 10px;

    .transportation-bus-model {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-title;
      color: $primary-color;
    }

    .transportation-seat {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-body;
      color: $primary-color;
    }

    .transportation-value {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-body;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .announcement-card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgb(226, 224, 224);

    .announcement-image-container {
      position: relative;

      .announcement-image {
        width: 100%;
        height: 220px;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .announcement-title {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-title;
      color: $primary-color;
      // font-weight: bold;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
    }

    .announcement-des {
      font-family: $font-kantumruy-pro;
      font-size: $font-size-body;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // color: $primary-color;
    }
  }
}
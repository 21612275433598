@import "../themes.scss";

.login-container {
  width: 100%;
  height: 100vh;
  // background-image: url("../Assets/angkor-picture.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 40% 50%;

  @include desktop {
    background-size: 80%;
    background-position: 60% 18%;
  }
  @include laptop {
    background-size: 100%;
    background-position: 100% 14%;
  }
  @include tablet {
    background-size: 120%;
    background-position: 60% 12%;
  }
  @include mobile-large {
    background-size: 140%;
    background-position: 50% 11%;
  }

  .box-container {
    width: 350px;
    border-radius: 8px;
    background-color: #fff;
    padding: 20px 25px;
    .login-subtitle {
      font-size: $font-size-title;
      font-family: $font-kantumruy-pro;
      font-weight: bold;
      // color: #5a6771;
      color: $primary-color;
    }

    // .box-text {
    //   display: flex;
    //   justify-content: center;

    //   .title-login {
    //     .title {
    //       color: #fff;
    //       font-weight: bold;

    //       @include mobile-large {
    //         font-size: 14px;
    //         font-weight: 500;
    //       }
    //     }

    //     .sub-title {
    //       color: #fff;
    //     }
    //   }
    // }

    .box-login {
      display: flex;
      justify-content: center;
      .input-login {
        font-size: $font-size-body;
        font-family: $font-kantumruy-pro;
      }
      .label-login {
        font-size: $font-size-body;
        font-family: $font-kantumruy-pro;
        color: $primary-color;
      }
      .forgot-pwd {
        font-size: $font-size-body;
        font-family: $font-kantumruy-pro;
        color: $primary-color;
        text-align: right;
        text-decoration: underline;
      }

      .icon {
        color: $primary-color;
      }
      .viewpw-icon {
        cursor: pointer;
      }
    }
    .forgot-password {
      font-size: 12px;
      color: $primary-color;
    }
    .btn-sign-in {
      margin-top: 18px;
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      background-color: $primary-color;
    }
    .footer-copy-right {
      margin-top: 40px;
      font-size: 12px !important;
      font-family: $font-kantumruy-pro !important;
      text-align: center;
    }
  }
}

@import "../themes.scss";

.dialog-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-title;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-kantumruy-pro;
  }

  .close-icon {
    color: red;
  }

  .avater-image {
    width: 140px;
    height: 140px;
    object-fit: cover !important;
  }

  .student-image {
    width: 150px;
    height: 150px;
  }

  .image-title {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    color: $primary-color;
    text-align: center;
  }

  .field-title {
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    color: $primary-color;
    margin-bottom: 2px;
  }

  .btn-add {
    background-color: $primary-color !important;
    font-family: $font-kantumruy-pro;
    font-size: $font-size-body;
    border-radius: 5px;
    height: 32px;
    color: #fff;
  }

  .btn-create {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
  }

  .btn-delete {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $background-delete !important;
    color: #fff;
    border-radius: 8px;
    font-family: $font-kantumruy-pro;
    font-weight: bold;
  }
}
@import "../themes.scss";

.forgotpassword-page {
  width: 100%;
  height: 100vh;
  // background-color: $primary-color;

  .text-field {
    background-color: #fff;
    border-radius: 6px;

    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
      color: darkblue !important;
    }

  }

  .background-image {
    // background-image: url("../Assets/backgroundImages.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 50%;
    position: fixed;
    opacity: 0.2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .back-btn {
    height: 10%;

    .text-back {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title-back {
        color: $primary-color;
        text-transform: none;
      }
    }
  }

  .container {
    width: 500px;
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    margin: auto 0;

    .box-logo {
      display: flex;
      justify-content: center;
    }

    .box-text {
      display: flex;
      justify-content: center;

      .title {
        color: grey;
        font-weight: bold;
      }

      .sub-title {
        color: black;
      }
    }

    .box-login {
      display: flex;
      justify-content: center;

      .icon {
        color: $primary-color;
      }

      .btn-sign-in {
        background-color: $primary-color;
        color: #fff;
        font-weight: bold;
        text-transform: none;
        font-size: 18px;
        // :hover{
        //     background-color: red;
        // }
      }
    }
  }
}